import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { FaUserCircle } from "react-icons/fa";
import profilPhoto from "../../ASSETS/Images/girl-social-media.jpg";
import { IoIosPower } from "react-icons/io";
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n'; // Assurez-vous que ce chemin est correct
import { useTranslation, initReactI18next } from 'react-i18next';
import i18next from 'i18next';

// Initialiser i18next
i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export function MonCompte() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const [inputs, setInputs] = useState({});
  const [comptes, setComptes] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [info, setInfo] = useState("");

  const logOutUser = () => {
    localStorage.clear();
    toast.success("Déconnexion avec succès !");
    navigate("/");
  };

  const logOutUse = () => {
    localStorage.clear();
    toast.success("Déconnexion avec succès !");
    navigate("/Mot_de_passe_oublie");
  };

  const [data, setData] = useState(() => {
    const saved = localStorage.getItem("data");
    return saved ? JSON.parse(saved) : null;
  });

  const userId = data ? data.data?.userId || data.userId : null;

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/compter/${userId}`);
        setInfo(response.data.info);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  const [firstname, setFirstname] = useState(data ? data.data?.userName || data.userName : "");
  const [lastname, setLastname] = useState(data ? data.data?.userLastname || data.userLastname : "");
  const [numero, setNumero] = useState(data ? data.data?.userTel || data.userTel : "");
  const [adressemail, setUserEmail] = useState(data ? data.data?.userMail || data.userMail : "");
  const [Picture, setPicture] = useState(data ? data.data?.Picture || data.Picture : "");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(values => ({ ...values, [name]: value }));
  };

  const submit = (event) => {
    event.preventDefault();
    setLoading(true);

    const templateParams = {
      firstname,
      lastname,
      adressemail,
      numero,
      userId,
    };

    axios.post(`${process.env.REACT_APP_BASE_URL}/api/compte`, templateParams)
      .then((response) => {
        if (response.data.status === 200) {
          setLoading(false);
          toast.success(t('main.modifiées'));

          // Mise à jour des données dans le stockage local
          const updatedData = {
            ...data,
            data: {
              ...data.data,
              userName: firstname,
              userLastname: lastname,
              userMail: adressemail,
              userTel: numero,
              // Assurez-vous d'ajouter d'autres champs si nécessaire
            }
          };
          localStorage.setItem("data", JSON.stringify(updatedData));

          // Mettre à jour les états locaux avec les nouvelles informations
          setFirstname(firstname);
          setLastname(lastname);
          setUserEmail(adressemail);
          setNumero(numero);
        } else {
          toast.error(t('main.Échec'));
        }
      })
      .catch((error) => {
        console.error("Une erreur s'est produite :", error.response ? error.response.data : error.message);
        toast.error("Une erreur s'est produite. Veuillez réessayer plus tard");
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Button onClick={openDrawer} variant="text" className="flex flex-row items-center space-x-2 text-[#FC4F4F]">
        <FaUserCircle className="text-xl" />
        <div>{t('main.Moncompte')}</div>
      </Button>
      <Drawer open={open} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between p-4">
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="h-5 w-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </IconButton>
        </div>
        <div className="px-4 flex flex-col items-center">
          <div className="flex flex-col items-center space-y-1">
            <div className="photo_profil rounded-full">
              <img src={info.Picture ? `data:image/png;base64,${info.Picture}` : profilPhoto} alt="Profile" className="h-16" />
            </div>
            <div>{firstname} {lastname}</div>
            <div className="text-sm">{adressemail}</div>
          </div>
          <form onSubmit={submit} className="pt-6 flex flex-col space-y-5">
            <input type="text" name='firstname' value={firstname} onChange={(e) => setFirstname(e.target.value)} placeholder="Nom" className="w-[250px] h-9 rounded-lg border-[#B0B0B0] border-2 px-4 text-sm outline-[#FC4F4F]" />
            <input type="text" name='lastname' value={lastname} onChange={(e) => setLastname(e.target.value)} placeholder="Prénom" className="w-[250px] h-9 rounded-lg border-[#B0B0B0] border-2 px-4 text-sm outline-[#FC4F4F]" />
            <input type="email" name='adressemail' value={adressemail} onChange={(e) => setUserEmail(e.target.value)} placeholder="Adresse mail" className="w-[250px] h-9 rounded-lg border-[#B0B0B0] border-2 px-4 text-sm outline-[#FC4F4F]" />
            <input type="tel" name='numero' value={numero} onChange={(e) => setNumero(e.target.value)} placeholder="Numero de portable" className="w-[250px] h-9 rounded-lg border-[#B0B0B0] border-2 px-4 text-sm outline-[#FC4F4F]" />
            <input type="hidden" name='userId' value={userId} />
            <input type="submit" value={t('main.Modifier')} className="w-[250px] h-9 rounded-lg text-white text-sm bg-[#FC4F4F] cursor-pointer" />
          </form>
        </div>
        <Link onClick={logOutUse} to='/Mot_de_passe_oublie' className="mt-3 ml-5 flex items-center justify-center" variant="text" size="sm">
          {t('main.Obtenir')}
        </Link>
        <button onClick={logOutUser} className="flex flex-row items-center space-x-2 pt-4 px-6 hover:text-[#30266D]">
          <IoIosPower className="text-lg" />
          <div>{t('main.déconnecter')}</div>
        </button>
      </Drawer>
      <ToastContainer />
    </React.Fragment>
  );
}
