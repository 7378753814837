import { Navigate } from "react-router-dom";

const AuthGuard = ({children}) => {
    let logged =  localStorage.getItem("data");

    if(!logged){
        return <Navigate to="/"></Navigate>
    }

    return children
};

export default AuthGuard;
