import React, { useEffect, useState } from 'react';
import { Card, Typography } from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
export function OrdreNumero({ numeros }) {
    const { t, i18n } = useTranslation();
    const [clients, setClients] = useState([]);
    const [customInterval, setCustomInterval] = useState(30); // par défaut à 30 minutes

    // const deleteClient = async (client) => {
    //     console.log(client);
    //     try {
    //         const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/delete/`,client);
    //           if (response) {
    //                 const reservations = response.data.reservations;
    //                 console.log(reservations)
    //               console.log('Client deleted successfully');
    //           }
    //     } catch (error) {
    //         console.error('There was a problem with the fetch operation:', error);
    //     }
    // };
    const deleteClient = async (clientToDelete) => {
        console.log(clientToDelete);
        try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/delete/${clientToDelete}` );
            if (response) {
                const reservations = response.data.reservations;
                console.log(reservations);
                console.log('Client deleted successfully');
            }
        } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        }
    };

    useEffect(() => {
        const initialClients = Object.values(numeros).filter(item => typeof item === 'object').map(client => {
            const numeroClient = client.original.numeroClient;
            let countdown = null;
            switch (client.original.subscription_frequency) {
                case 'daily':
                    countdown = 1440 * 60; // secondes dans une journée
                    break;
                case 'week':
                    countdown = 10080 * 60; // secondes dans une semaine
                    break;
                case 'month':
                    countdown = 43200 * 60; // secondes dans un mois (supposant 30 jours)
                    break;
                case 'year':
                    countdown = 525600 * 60; // secondes dans une année
                    break;
                case 'hourly':
                    countdown = 60 * 60; // secondes dans une heure
                    break;
                case 'custom':
                    countdown = customInterval * 60; // intervalle personnalisé en secondes
                    break;
                default:
                    countdown = null;
            }
            
            // Vérifier le localStorage pour le compte à rebours existant et l'heure de début
            const savedData = localStorage.getItem(`client_${numeroClient}`);
            if (savedData) {
                const { savedCountdown, lastUpdated } = JSON.parse(savedData);
                const timeElapsed = Math.floor((Date.now() - lastUpdated) / 1000); // temps écoulé en secondes
                countdown = Math.max(savedCountdown - timeElapsed, 0); // S'assurer que le compte à rebours ne descend pas en dessous de 0
            }

            return {
                ...client,
                countdown: countdown,
                expired: countdown === 0,
                expiryTimestamp: null // Initialiser avec null
            };
        });

        // Préserver l'état des clients existants
        setClients(prevClients => {
            const updatedClients = initialClients.map(newClient => {
                const existingClient = prevClients.find(client => client.original.numeroClient === newClient.original.numeroClient);
                return existingClient || newClient;
            });
            return updatedClients;
        });
    }, [numeros, customInterval]);

    useEffect(() => {
        const interval = setInterval(() => {
            setClients(prevClients => {
                return prevClients.map(client => {
                    if (client.countdown > 0) {
                        const newCountdown = client.countdown - 1;
                        // Enregistrer le compte à rebours mis à jour et l'heure actuelle dans le localStorage
                        localStorage.setItem(`client_${client.original.numeroClient}`, JSON.stringify({
                            savedCountdown: newCountdown,
                            lastUpdated: Date.now()
                        }));
                        return {
                            ...client,
                            countdown: newCountdown,
                            expired: newCountdown === 0,
                            expiryTimestamp: newCountdown === 0 ? Date.now() :  client.expiryTimestamp
                        };
                    } else if (client.countdown === 0 && !client.expired) {
                        
                        deleteClient(client); // Appeler l'API de suppression
                        return {
                            ...client,
                            expired: true,
                            expiryTimestamp: Date.now()
                        };
                    }
                    return client;
                });
            });
        }, 1000); // 1000 ms = 1 seconde

        return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage du composant
    }, []);

    // useEffect(() => {
    //     const removalInterval = setInterval(() => {
             
    //         setClients(prevClients => {
    //             const currentTime = Date.now();
                
    //             return prevClients.filter(client => !client.expired || (client.expiryTimestamp && (currentTime - client.expiryTimestamp) < 300000)      );
    //         });
    //     }, 1000); // Vérifier toutes les secondes

    //     return () => clearInterval(removalInterval); // Nettoyer l'intervalle lors du démontage du composant
    // }, []);
//     useEffect(() => {
//     const removalInterval = setInterval(() => {
//       setClients(prevClients => {
//         const currentTime = Date.now();
//         //console.log('Checking clients at', currentTime); // Log the current time
//         return prevClients.filter(client => {
//            // console.log(clients);
//           const isExpired = client.expired || (client.expiryTimestamp && (currentTime - client.expiryTimestamp) < 300000);
//           //console.log(Client ${client.nom} expiré: ${client.expired}, isExpired: ${isExpired}); // Log each client's status
//           console.log(isExpired)
//           if (clients.expiré || isExpired) {
//             deleteClient(client['id']);
//             console.log(client['id']);
//             return false;
//           }
//           return true;
//         });
//       });
//     }, 1000);

//     return () => clearInterval(removalInterval);
//   }, []);

    useEffect(() => {
        const removalInterval = setInterval(async () => {
        const currentTime = Date.now();
        const clientsToRemove = [];
        
        setClients(prevClients => {
            return prevClients.filter(client => {
                const isExpired = client.expired || (client.expiryTimestamp && (currentTime - client.expiryTimestamp) < 300000);
                 console.log(isExpired)
                if (client.expired || isExpired) {
                    clientsToRemove.push(client); // Collect clients to be deleted
                    console.log(clientsToRemove);
                     // Delete expired clients asynchronously
                    for (const client of clientsToRemove) {
                        deleteClient(client['id']);
                    }
                    return false;
                }
                return true;
            });
        });
        console.log(clientsToRemove);

       
        }, 1000); // Vérifier toutes les secondes

        return () => clearInterval(removalInterval); // Nettoyer l'intervalle lors du démontage du composant
    }, [])

    const handleCustomIntervalChange = (event) => {
        setCustomInterval(parseInt(event.target.value, 10));
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours}h ${minutes}m ${secs}s`;
    };

    return (
        <>
            <Card className="w-full overflow-y-scroll h-[590px] rounded-none">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            <th className="bg-[#30266D] text-white p-4">
                                <Typography variant="small" color="blue-gray" className="font-normal text-white leading-none text-lg">
                                    N° {t('main.ordre')}
                                </Typography>
                            </th>
                            <th className="bg-[#30266D] text-white p-4">
                                <Typography variant="small" color="blue-gray" className="font-normal text-white leading-none text-lg">
                                    N° {t('main.duticket')}
                                </Typography>
                            </th>
                            <th className="bg-[#30266D] text-white p-4">
                                <Typography variant="small" color="blue-gray" className="font-normal text-white leading-none text-lg">
                                    {t('main.Remaining')}
                                </Typography>
                            </th>
                            <th className="bg-[#30266D] text-white p-4">
                                <Typography variant="small" color="blue-gray" className="font-normal text-white leading-none text-lg">
                                    {t('main.Noms')}
                                </Typography>
                            </th>
                            <th className="bg-[#30266D] text-white p-4">
                                <Typography variant="small" color="blue-gray" className="font-normal text-white leading-none text-lg">
                                    Services
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.map((client, index) => (
                            <tr key={index} className={`${index % 2 === 0 ? "even:bg-blue-gray-50/50" : ""} ${client.expired ? "bg-red-500" : ""}`}>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal text-lg">
                                        {index + 1}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal text-lg">
                                        {client.original.numeroClient || ""}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal text-lg">
                                        {client.countdown !== null ? formatTime(client.countdown) : ""}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal text-lg">
                                        {(client.original.first_name || "") + " " + (client.original.last_name || "")}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="blue-gray" className="font-normal text-lg">
                                        {i18n.language === 'fr' ? (client.original.fr_Name || "") : (client.original.Names || "")}
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Card>
        </>
    );
}
