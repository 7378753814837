import React, { useState, useEffect } from 'react';
import ConnexionBack from '../../ASSETS/Images/girl-social-media.jpg';
import { Link } from "react-router-dom";
import Loader from '../../COMPONENTS/Loader';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; // Assurez-vous que ce chemin est correct
import { openDB } from 'idb';

function Connexion() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [inputs, setInputs] = useState({});
  const [data, setData] = useState(() => {
    const saved = localStorage.getItem("data");
    return saved !== null ? JSON.parse(saved) : null;
  });

  const [url, setUrl] = useState(() => {
    const link = localStorage.getItem("url");
    return link !== null ? JSON.parse(link) : null;
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      navigate('/Service');
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [data, navigate]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/login`, inputs)
      .then((response) => {
        if (response.data.status === 201) {
          toast.success(t('main.avec'), {
            onClose: () => {
              localStorage.setItem('data', JSON.stringify(response.data));
              url !== null ? navigate(url) : navigate('/Service');
            }
          });
          setLoading(true);
        } else {
          toast.error(t('main.utilisate'));
        }
      })
      .catch((error) => {
        toast.error("Erreur merci de ressayer");
        console.log(error);
        localStorage.clear();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ backgroundImage: `url(${ConnexionBack})` }} className='bg-cover flex justify-center bg-center bg-no-repeat h-screen'>
            <div className='absolute top-0 right-0 mr-4 mt-4'>
              {i18n.language === 'fr' ? (
                <button onClick={() => changeLanguage('en')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  English
                </button>
              ) : (
                <button onClick={() => changeLanguage('fr')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  Français
                </button>
              )}
            </div>
            <form onSubmit={handleSubmit} className='bg-[#30266D] h-screen w-[500px] text-white flex flex-col items-center justify-center space-y-6'>
              <div className='text-3xl font-semibold'>{t('main.Bienvenue')}</div>
              <div className='w-[350px] text-center py-2'>{t('main.entrer')}</div>
              <input type='text' name='mail' value={inputs.mail || ''} onChange={handleChange} className='w-[330px] h-12 rounded-full px-6 text-black outline-none' placeholder={t('main.portable')} required />
              <input type='password' name='password' value={inputs.password || ''} onChange={handleChange} className='w-[330px] h-12 rounded-full px-6 text-black outline-none' placeholder={t('main.secret')} required />
              <Link to="/Mot_de_passe_oublie" className='relative left-16 hover:text-[#FC4F4F]'>{t('main.Mot')} ?</Link>
              <input type='submit' className='w-[330px] h-12 rounded-full bg-[#FC4F4F] hover:bg-white hover:text-[#FC4F4F] cursor-pointer' value={t('main.connecter')} />
              <ToastContainer />
              <div className='flex flex-row items-center space-x-2 pt-6'><div>{t('main.Pas')} ?</div> <Link to='/Inscription' className='font-bold'>{t('main.Inscrivez')}</Link></div>
            </form>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  );
}

export default Connexion;
