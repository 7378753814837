import React from 'react'
import { useState, useEffect } from 'react'
import Loader from '../../COMPONENTS/Loader'
import ConnexionBack from '../../ASSETS/Images/girl-social-media.jpg'
import { Link } from "react-router-dom"
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n'; // Assurez-vous que ce chemin est correct
import { useTranslation, initReactI18next } from 'react-i18next';
import i18next from 'i18next';

function Connexion() {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({})

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const { t, i18n } = useTranslation();

  const [data, setData] = useState(() => {
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });

  const [url, setUrl] = useState(() => {
    const link = localStorage.getItem("url");
    const value = link !== null ? JSON.parse(link) : null;
    return value;
  });

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      navigate('/Connexion')
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // 2000 milliseconds = 2 seconds

    return () => clearTimeout(timer);
  }, [data, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axios.post(`${process.env.REACT_APP_BASE_URL}/api/Mot_de_passe_oublie`, inputs)
      .then((response) => {
        if (response.data.status === 201) {
          toast.success(t('main.nouvelles'), {
            onClose: () => {
              navigate('/Connexion');
            }
          });
          setLoading(true);
        } else {
          toast.error(t('main.client'));
        }
      })
      .catch((error) => {
        toast.error("Erreur merci de ressayer");
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      })
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ backgroundImage: `url(${ConnexionBack})` }} className=' bg-cover flex justify-center bg-center bg-no-repeat h-screen '>
            <div className='absolute top-0 right-0 mr-4 mt-4'>
              {i18n.language === 'fr' ? (
                <button onClick={() => changeLanguage('en')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  English
                </button>
              ) : (
                <button onClick={() => changeLanguage('fr')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  Français
                </button>
              )}
            </div>
            <div className='bg-[#30266D] h-screen w-[500px] text-white flex flex-col items-center justify-center space-y-6 '>
              <div className='text-3xl font-semibold '>{t('main.Cordia')} </div>
              <div className='w-[350px] text-center py-2'>{t('main.Cordi')} </div>
              <form onSubmit={handleSubmit} className="bg-[#30266D] text-white flex flex-col items-center justify-center space-y-6">
                <input type='text' name='Emails' value={inputs.Emails} onChange={handleChange} className='w-[330px] h-12 rounded-full px-6 text-black outline-none' placeholder={t('main.Cor')} />
                <input type='submit' className='w-[330px] h-12 rounded-full bg-[#FC4F4F] hover:bg-white hover:text-[#FC4F4F] cursor-pointer' value={t('main.Cordialement')} />
              </form>
              <Link to='/Connexion' className=' font-bold'>{t('main.Cordialemen')}</Link>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </>
  )
}

export default Connexion;
