import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BiSolidLike, BiSolidDislike } from "react-icons/bi";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import HomeBack from '../../ASSETS/Images/Group14.png';
import Loader from '../../COMPONENTS/Loader';
import '../../index.css'; // Ajouter ce chemin si nécessaire

// Initialiser i18next
i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

function Home() {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ backgroundImage:`url(${HomeBack})`}} className=' bg-cover bg-center bg-no-repeat h-screen sm:pl-20 flex s:items-center relative'>
            <div className='absolute top-0 right-0 mr-4 mt-4'>
              {i18n.language === 'fr' ? (
                <button onClick={() => changeLanguage('en')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  English
                </button>
              ) : (
                <button onClick={() => changeLanguage('fr')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  Français
                </button>
              )}
            </div>
            <div className='flex flex-col items-center'>
              <div className='md:text-9xl sm:text-9xl s:text-4xl font-medium md:w-[500px] text-white text-center'>{t('main.compte')} ?</div>
              <div className='flex flex-row md:space-x-20 s:space-x-9 pt-12'>
                <Link to="/Connexion" className='flex flex-row space-x-2 items-center justify-center bg-[#FC4F4F] text-3xl text-white hover:bg-white hover:text-[#FC4F4F] py-2 rounded-full w-[130px]'>
                  <BiSolidLike />
                  <p>{t('main.Oui')}</p>
                </Link>
                <Link to="/Inscription" className='flex flex-row space-x-2 items-center bg-white text-3xl text-[#30266D] hover:bg-[#30266D] hover:text-white py-2 px-3 rounded-full w-[130px]'>
                  <BiSolidDislike />
                  <p>{t('main.Non')}</p>
                </Link>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      )}
    </>
  )
}

export default Home;
