import React, { useState, useEffect } from 'react';
import Loader from '../../COMPONENTS/Loader';
import axios from 'axios';
import { OrdreNumero } from './OrdreNumero';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n'; // Assurez-vous que ce chemin est correct

export default function Moniteur() {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [numero, setNumero] = useState([]);
    const [aujoudhui, setAujoudhui] = useState("");
    const [info, setInfo] = useState("");

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/ecran`);
                if (response.data.status === 200) {
                    const reservations = response.data.reservations;
                    //console.log(reservations)
                    if (reservations) {
                        setNumero(reservations);
                    } else {
                        console.error('Aucune réservation n\'a été récupérée ou elles ne sont pas dans le bon format.');
                    }
                    setAujoudhui(response.data.aujoudhui);
                    setInfo(response.data.info.logo);
                    toast.success(t('main.follow_number')); // Utilisez la fonction t pour traduire le message
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        const intervalId = setInterval(fetchData, 30000);

        return () => clearInterval(intervalId);
    }, [t]);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="bg-[#2F2F2F] md:h-screen">
                    <div className="flex md:flex-row flex-col">
                        <div className="md:w-1/3">
                            <OrdreNumero numeros={numero} />
                        </div>
                        <div className="md:w-2/3 p-6">
                            <div className='absolute top-0 right-0 mr-4 mt-4'>
                                {i18n.language === 'fr' ? (
                                    <button onClick={() => changeLanguage('en')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                                    English
                                    </button>
                                ) : (
                                    <button onClick={() => changeLanguage('fr')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                                    Français
                                    </button>
                                )}
                            </div>
                            <div className="flex flex-row items-center justify-between text-white">
                                <div className="text-4xl"><img src={`data:image/png;base64,${info ? info : ""}`} alt="Logo" className="h-16" /></div>
                                <div className="text-lg">{aujoudhui}</div>
                            </div>
                            <div className="video_moniteur mt-8">
                                <video className="h-full w-full rounded-none" controls autoPlay>
                                    <source src="https://docs.material-tailwind.com/demo.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="annonce text-white bg-[#CD2A2A]">
                        <p>{t('main.remplissant')}</p>
                    </div>
                </div>
            )}
        </>
    );
}
