import React, { useState, useEffect } from 'react';
import ConnexionBack from '../../ASSETS/Images/girl-social-media.jpg';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../COMPONENTS/Loader';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n'; // Assurez-vous que ce chemin est correct
import { useTranslation, initReactI18next } from 'react-i18next';
import i18next from 'i18next';

// Initialiser i18next
i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});


function Inscription() {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [loading, setLoading] = useState(true);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }));
  };

  const [data, setData] = useState(() => {
    const saved = localStorage.getItem("data");
    const initialValue = saved !== null ? JSON.parse(saved) : null;
    return initialValue;
  });

  const [url, setUrl] = useState(() => {
    const link = localStorage.getItem("url");
    const value = link !== null ? JSON.parse(link) : null;
    return value;
  });

  const { t, i18n } = useTranslation();


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    if (data) {
      navigate('/Inscription');
    }
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [data, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${process.env.REACT_APP_BASE_URL}/api/register`, inputs)
    .then((response) => {
        if (response.data.status === 201) {
          setLoading(true);
          navigate("/Inscription");
          setInputs({
            numero: "",
            password: "",
          });
          toast.success(t('main.avecsucces'), { autoClose: 1000 });
        } else {
          toast.error(response.data.message, { autoClose: 1000 });
        }
      })
      .catch((error) => {
        console.error("Une erreur est survenue :", error);
        console.error("Erreur de réponse :", error.response); 
        toast.error("Une erreur est survenue", { autoClose: 1000 });
      });      
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ backgroundImage: `url(${ConnexionBack})` }} className='bg-cover flex justify-center bg-center bg-no-repeat h-screen'>
           <div className='absolute top-0 right-0 mr-4 mt-4'>
              {i18n.language === 'fr' ? (
                <button onClick={() => changeLanguage('en')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  English
                </button>
              ) : (
                <button onClick={() => changeLanguage('fr')} className='flex flex-row items-center space-x-2 text-[#FC4F4F]'>
                  Français
                </button>
              )}
            </div>
          <form onSubmit={handleSubmit} className='bg-[#FC4F4F] h-screen w-[500px] text-white flex flex-col items-center justify-center space-y-6'>
            <div className='text-3xl font-semibold'>{t('main.Bienvenue')}</div>
            <div className='w-[350px] text-center py-2'>{t('main.enregistrer')}</div>
            <input type='text' name='firstname' value={inputs.firstname || ''} onChange={handleChange} className='w-[330px] h-12 rounded-full px-6 text-black outline-none' placeholder={t('main.Nom')} required />
            <input type='text' name='lastname' value={inputs.lastname || ''} onChange={handleChange} className='w-[330px] h-12 rounded-full px-6 text-black outline-none' placeholder={t('main.Prenom')} required />
            <input type='email' name='adressemail' value={inputs.adressemail || ''} onChange={handleChange} className='w-[330px] h-12 rounded-full px-6 text-black outline-none' placeholder={t('main.Mail')} required />
            <input type='tel' name='numero' value={inputs.numero || ''} onChange={handleChange} className='w-[330px] h-12 rounded-full px-6 text-black outline-none' placeholder={t('main.Cell')} required />
            <input type='submit' className='w-[330px] h-12 rounded-full bg-[#30266D] hover:bg-white hover:text-[#30266D] cursor-pointer' value={t('main.Register')} />
            <div className='flex flex-row items-center space-x-2 pt-6'>
              <div>{t('main.Déjà')} ?</div> 
              <Link to='/Connexion' className='font-bold'>{t('main.Connectez')}</Link>
            </div>
          </form>
        </div>
      )}
      <ToastContainer />
    </>
  );
}

export default Inscription;
